@font-face {
  font-family: "mr-icons";
  src: url("mr-icons.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*="icon-"] {
  font-family: "mr-icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: normal;
  /* Better Font Rendering =========== */ 
}

.icon-mr-ready:before {
  content: "\e900";
}

.icon-left:before {
  content: "\e901";
}

.icon-right:before {
  content: "\e902";
}

.icon-live:before {
  content: "\e903";
}

.icon-bad-connection:before {
  content: "\e904";
}

.icon-navigation:before {
  content: "\e905";
}

.icon-refresh-ccw:before {
  content: "\e906";
}

.icon-rotate-cw:before {
  content: "\e907";
}

.icon-rotate-ccw:before {
  content: "\e908";
}

.icon-arrow-down-circle:before {
  content: "\e909";
}

.icon-arrow-up-circle:before {
  content: "\e90a";
}

.icon-arrow-right-circle:before {
  content: "\e90b";
}

.icon-arrow-left-circle:before {
  content: "\e90c";
}

.icon-command:before {
  content: "\e90d";
}

.icon-help-circle:before {
  content: "\e90e";
}

.icon-share:before {
  content: "\e90f";
}

.icon-globe:before {
  content: "\e910";
}

.icon-smile:before {
  content: "\e911";
}

.icon-zap:before {
  content: "\e912";
}

.icon-volume-1:before {
  content: "\e913";
}

.icon-volume-2:before {
  content: "\e914";
}

.icon-volume:before {
  content: "\e915";
}

.icon-volume-x:before {
  content: "\e916";
}

.icon-fullscreen:before {
  content: "\e917";
}

.icon-fullscreen-exit:before {
  content: "\e918";
}
